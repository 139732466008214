angular.module('dnecom.component.desktopTimer')
    .directive('timerCountdown', ["TimerProvider", "$rootScope", "$timeout", "$translate", function(TimerProvider, $rootScope, $timeout, $translate) {
        return {
            scope: {
                mobile: '=mobile'
            },
            link: function(scope, element) {
                function initialize() {
                    var timerDate = TimerProvider.date();

                    if (!timerDate) {
                        $timeout(function() {
                            $rootScope.$broadcast('timer.cleanup');
                        });

                        return;
                    }

                    $(element).countdown({
                        format: 'DHMS',
                        until: timerDate.toDate(),
                        alwaysExpire: true,
                        labels: ['Years', 'Months', 'Weeks', $translate.instant('product_page.days'), $translate.instant('product_page.hours'), $translate.instant('product_page.minutes'), $translate.instant('product_page.seconds')],
                        labels1: ['Years', 'Months', 'Weeks', $translate.instant('product_page.days'), $translate.instant('product_page.hours'), $translate.instant('product_page.minutes'), $translate.instant('product_page.seconds')],
                        onExpiry: function() {
                            $(element).countdown('destroy');

                            TimerProvider.expire(scope.mobile);

                            if (!TimerProvider.repeating()) {
                                $timeout(function() {
                                    $rootScope.$broadcast('timer.cleanup');
                                });

                                return;
                            }

                            initialize()
                        }
                    });
                }

                initialize();
            }
        }
    }]);
