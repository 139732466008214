angular.module('dnecom.frontend.desktop', ['dnecom.frontend', 'dnecom.component.desktopTimer', 'pascalprecht.translate'])
    .config(["$translateProvider", function ($translateProvider) {
        try {
            let translations = TemplateData.value('translations');
        } catch (e) {
            let translations = {};
        }
        $translateProvider.translations('en', translations).preferredLanguage('en');
    }]).filter('replace', [function () {
        return function (input, from, to) {
            if(input === undefined) {
                return;
            }
            var regex = new RegExp(from, 'g');
            return input.replace(regex, to);
        };
    }]);

